import axios from 'axios';
import { take, call, put, all } from 'redux-saga/effects';
import { concat, get } from 'lodash';
import parseLinkHeader from 'parse-link-header';

import { removeHost } from '../utils';

import { REQUEST, GET, GET_CONSULTANTS, PUT } from '../actions/LeadSwitch';
import { EndpointName } from '../constants/EndpointName';
import saveLeadHistory from './LeadHistory/saveLeadHistory';

const LONG_REQUEST_ITEM_COUNT = 50;

const leadSwitchTypes = {
  manual: 'LeadSwitchManual',
  import: 'LeadSwitchImport',
};

export function* getLeads(staffMemberId) {
  try {
    yield put({ type: `${GET}_PENDING` });
    //get all leads from selected consultant

    const url = `${EndpointName.WORK_LEAD_MANAGEMENT}/`
    + `?and(eq(staffMemberId,string:${staffMemberId}),`
    + 'out(customerTaskStatus.number,(string:52,string:53,string:55,string:50,string:2)))'
    + '&limit(999999)&select(customerId,customerTaskStatus,customerTaskType,orderOrigin,note,additionalEditor,staffMemberId)';

    const result = yield call(axios.get, url);
    const customerIds = [...new Set(result.data.map((lead) => lead.customerId))];

    // fetch Customers
    const customerIdsSplited = [];
    for (let i = 0; i < Math.ceil(customerIds.length / LONG_REQUEST_ITEM_COUNT); i++) {
      const start = i * LONG_REQUEST_ITEM_COUNT;
      customerIdsSplited[i] = customerIds.slice(start, start + LONG_REQUEST_ITEM_COUNT);
    }
    let customerList = [];
    yield all(
      customerIdsSplited.map(
        (chunk) => new Promise((resolve, reject) => {
          axios({
            url: `${EndpointName.PERSON_CUSTOMER}/?in(id,(${chunk
              .map((id) => `string:${id}`)
              .join(',')}))&limit(999999)&select(customerNumberAlternate,designation,consultant)`,
            method: 'get',
          })
            .then((response) => {
              customerList = customerList.concat(response.data);
              resolve(response);
            })
            .catch((error) => {
              console.error(error);
              resolve(error);
            });
        })
      )
    );
    const customerByIdMap = {};
    customerList.forEach((customer) => {
      customerByIdMap[customer.id] = customer;
    });

    const leads = result.data.map((lead) => ({
      ...lead,
      customer: customerByIdMap[lead.customerId],
    }));

    yield put({ type: `${GET}_FULFILLED`, data: leads, staffMemberId });
  } catch (e) {
    yield put({ type: `${GET}_REJECTED` });
  }
}

export function* getConsultants() {
  try {
    yield put({ type: `${GET_CONSULTANTS}_PENDING` });

    //get all consultants to populate dropdowns
    let consultants = [];
    let nextconsultants = [];
    let linkHeaders = { next: { url: 'http://localhost/person/consultant/?limit(1000)&select(username,firstName,lastName)' } };
    while (linkHeaders.hasOwnProperty('next')) {
      nextconsultants = yield call(axios.get, removeHost(linkHeaders.next.url));
      consultants = concat(consultants, nextconsultants.data);
      linkHeaders = parseLinkHeader(nextconsultants.headers.link);
    }

    yield put({ type: `${GET_CONSULTANTS}_FULFILLED`, data: consultants });
  } catch (e) {
    yield put({ type: `${GET_CONSULTANTS}_REJECTED` });
  }
}

export function* setStaffMemberId(leadIds, staffMemberIdTo, consultantId, leadSwitchType) {
  try {
    yield put({ type: `${PUT}_PENDING` });
    for (let i = 0; i < leadIds.length; i++) {
      if (i % 10 === 0) {
        yield put({ type: `${PUT}_PROGRESS`, index: i, total: leadIds.length });
      }
      const patchdata =
        leadIds[i].staffMemberId === leadIds[i].additionalEditor
          ? [
            { op: 'replace', path: '/staffMemberId', value: staffMemberIdTo },
            { op: 'replace', path: '/additionalEditor', value: staffMemberIdTo },
          ]
          : [{ op: 'replace', path: '/staffMemberId', value: staffMemberIdTo }];
      yield call(axios.patch, `/work/leadmanagement/${leadIds[i].id}`, patchdata);
    }
    yield put({ type: `${PUT}_FULFILLED`, leadIds, staffMemberIdTo });

    const leadIdsStringList = (leadIds || []).map((leadId) => get(leadId, 'id'));
    yield call(saveLeadHistory, { consultantId, leadIds: leadIdsStringList, type: get(leadSwitchTypes, leadSwitchType) });
  } catch (e) {
    yield put({ type: `${PUT}_REJECTED` });
  }
}

export default function* leadSwitchFlow() {
  while (true) {
    const request = yield take(REQUEST);
    const { staffMemberId, leadIds, staffMemberIdTo, consultantId, leadSwitchType } = request;
    if (staffMemberId) {
      yield call(getLeads, staffMemberId);
    } else if (leadIds) {
      yield call(setStaffMemberId, leadIds, staffMemberIdTo, consultantId, leadSwitchType);
    } else {
      yield call(getConsultants);
    }
  }
}
