import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _, { get, set, defaultsDeep, isUndefined, isNull, omit, cloneDeep } from 'lodash';
import { Nav, NavItem, Button } from 'react-bootstrap';
import Toggle from 'react-toggle';
import update from 'immutability-helper';
import { arrayMove } from 'react-sortable-hoc';
import uuid from 'uuid';
import { withRouter } from 'react-router-dom';

import EditContainer from '../components/EditContainer';
import General from '../components/Workguide/General';
import Components from '../components/Workguide/DetailComponents';
import DetailInfos from '../components/Workguide/DetailInfos';
import Actions from '../components/Workguide/Actions/Actions';
import Approvals from '../components/Workguide/Approval/Approvals';

import * as workguideActions from '../actions/WorkguideActions';

class WorkguideDetail extends React.Component {
  constructor(props) {
    super(props);
    const { actions, productGroups } = props;

    this.state = {
      selectedTab: "Allgemein",
      tabs: [
        "Allgemein",
        "Formularfelder",
        "Freigabe",
        "Prozessdetails",
        "Info",
        "Hilfe",
        "FAQ",
        "Aktionen"
      ]
    }
    this.editValueField = this.editValueField.bind(this);
    this.addComponent = this.addComponent.bind(this);
    this.addFieldGroupComponent = this.addFieldGroupComponent.bind(this);
    this.delComponent = this.delComponent.bind(this);
    this.deleteWorkguide = this.deleteWorkguide.bind(this);
    this.duplicateComponent = this.duplicateComponent.bind(this);
    this.cloneWorkguide = this.cloneWorkguide.bind(this);
    this.requestUpdateService = _.debounce(this.requestUpdateService, 1000);
    this.onWorkguideActionsChange = this.onWorkguideActionsChange.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);
    this.requestUpdateService = this.requestUpdateService.bind(this);

    if (productGroups.length === 0) actions.workguideProductGroupsRequest();
    actions.workguideCodesRequest('workguideStatus');
    actions.workguideCodesRequest('workguideRejectReason');
    actions.workguideCodesRequest('workguideCategory');
    actions.workguideCodesRequest('orderOrigin');
    actions.workguideCodesRequest('businessRelevance');
    actions.workguideCodesRequest('archiveDocumentType');
    actions.workguideCodesRequest('documentFindCode');
  }

  componentWillMount() {
    const { workguide, actions, match } = this.props;
    const workguideId = get(match, 'params.workguideId');

    if (workguide.id === undefined || workguide.id !== workguideId) {
      actions.getWorkguideRequest(workguideId);
    }
  }

  handleSelect(eventKey) {
    this.setState({
      selectedTab: eventKey
    })
  }

  onSortEnd({ oldIndex, newIndex }) {
    const { workguide, actions } = this.props;
    let newOrder = arrayMove(workguide.components, oldIndex, newIndex);

    newOrder = newOrder.map((o, i) => {
      o.order = i * 100;
      return (o);
    });
    const newWorkguide = update(workguide, { components: { $set: newOrder } });
    actions.setData({ value: newWorkguide, key: 'workguide' });
    this.requestUpdateService(newWorkguide);
  };

  requestUpdateService(newValue) {
    this.props.actions.updateWorkguideRequest(newValue);
  }

  addFieldGroupComponent(fieldGroup, props) {
    this.addComponent(fieldGroup, props)
  }

  addComponent(fieldGroup, props = {}) {
    const { workguide, actions } = this.props;

    const id = `cmp${uuid.v4()}`.replace(/-/g, '');

    let newFormComponent = {
      component: "Input",
      order: _.get(_.maxBy(_.get(workguide, 'components', []), 'order'), 'order', 0) + 100,
      props: defaultsDeep(
        props,
        {
          id,
          name: id,
          type: "input",
          options: [],
          multi: false,
          title: {
            de: "",
            fr: "",
            en: ""
          }
        }
      )
    }

    if (fieldGroup) newFormComponent.fieldGroup = fieldGroup;

    const newWorkguide = update(workguide, {
      components: {
        $push: [newFormComponent]
      }
    });
    actions.setData({ value: newWorkguide, key: 'workguide' });
    this.requestUpdateService(newWorkguide);
  }

  duplicateComponent(id) {
    const { workguide, actions } = this.props;

    const component = get(workguide, 'components', []).find((c) => get(c, 'props.id') === id);
    const duplicate = cloneDeep(component);
    set(duplicate, 'props.id', `cmp${uuid.v4()}`.replace(/-/g, ''));

    const updated = update(workguide, {
      components: {
        $push: [duplicate]
      }
    });

    actions.setData({ value: updated, key: 'workguide' });
    this.requestUpdateService(updated);
  }

  delComponent(id) {
    const { workguide, actions } = this.props;
    let splice = [[id, 1]];

    const components = get(workguide, 'components', []);
    const component = get(components, id);
    if (get(component, `component`) === 'FieldGroup') {
      splice = [
        ...[...components].reverse().filter(c => get(c, 'fieldGroup') === get(component, 'props.id')).map(c => ([components.indexOf(c), 1])),
        [id, 1]
      ];
    }

    const newWorkguide = update(workguide, {
      components: {
        $splice: splice
      }
    });



    actions.setData({ value: newWorkguide, key: 'workguide' });
    this.requestUpdateService(newWorkguide);
  }

  updateTitle(id, value) {

    const { workguide, language } = this.props;

    let newWorkguide = workguide;
    const currentValues = get(workguide, `components.${id}.props.title`);
    if (language === 'de') {
      const currentValueDe = currentValues.de;

      // Iterate over the keys and update those that have the same value as 'de'
      for (let key in currentValues) {
        if (currentValues[key] === currentValueDe) {
          currentValues[key] = value;
        }
      }
      newWorkguide = update(workguide, {
        components: {
          [id]: {
            props: {
              title: { $set: currentValues }
            }
          }
        }
      });
    } else {
      newWorkguide = update(workguide, {
        components: {
          [id]: {
            props: {
              title: {
                [language]: { $set: value }
              }
            }
          }
        }
      });
    }
    return newWorkguide;
  }

  editValueField(id = null, type = null, value = null) {
    const { language, workguide } = this.props;
    let newWorkguide = { ...workguide };

    //event is a form element
    if (typeof id === 'object') {
      const name = _.split(id.target.name, '.');

      switch (_.last(name)) {
        // update form component title
        case "formFieldTitle":
          newWorkguide = this.updateTitle(name[0], id.target.value);
          break;
        //update target mailadress
        case "target":
          newWorkguide = update(workguide, {
            "target": { $set: id.target.value }
          })
          break;
        //update title or the responsible of the workguide
        default:
          if (_.first(name) === "responsible") {
            newWorkguide = update(workguide, {
              [_.first(name)]: {
                [_.last(name)]: {
                  $set: id.target.value
                }
              }
            })
          } else {
            newWorkguide = update(workguide, {
              [id.target.name]: {
                [language]: { $set: id.target.value }
              }
            });
          }
      }
    } else { //event is a non form value from multiselect for example
      switch (type) {
        case 'keyword':
          newWorkguide = update(workguide, {
            keyword: {
              [language]: { $set: value.map((o) => o.value) }
            }
          })
          break;
        case 'select':
          newWorkguide = update(workguide, {
            [id]: {
              $set: value.value
            }
          });

          if (get(value, 'value') === 'camunda') {
            newWorkguide = update(newWorkguide, {
              canBeSaved: { $set: false }
            })
          }
          break;
        case 'selectTranslatable':
          newWorkguide = update(workguide, {
            [id]: {
              [language]: {
                $set: value.value
              }
            }
          })
          break;
        case 'number':
          newWorkguide = update(workguide, {
            [id]: {
              $set: value
            }
          })
          break;
        case 'target':
          newWorkguide = update(workguide, {
            [id]: {
              $set: value
            }
          })
          break;

        case 'text':
          newWorkguide = { ...workguide };
          if (!_.has(newWorkguide, id)) newWorkguide = update(newWorkguide, { [id]: { $set: {} } });

          newWorkguide = update(newWorkguide, {
            [id]: {
              [language]: {
                $set: value
              }
            }
          })
          break;
        case 'toggle':
          newWorkguide = update(workguide, {
            [id]: {
              $set: !workguide[id]
            }
          })
          break;
        case 'noCustomerRequired':
          newWorkguide = { ...workguide };
          if (!_.has(newWorkguide, 'noCustomerRequired')) {
            newWorkguide.noCustomerRequired = true;
          } else {
            newWorkguide = update(newWorkguide, {
              noCustomerRequired: {
                $set: !newWorkguide.noCustomerRequired
              }
            });
          }
          break;
        case 'canBeSaved':
          newWorkguide = { ...workguide };
          if (!_.has(newWorkguide, 'canBeSaved')) {
            newWorkguide.canBeSaved = false;
          } else {
            newWorkguide = update(newWorkguide, {
              canBeSaved: {
                $set: !newWorkguide.canBeSaved
              }
            });
          }
          break;
        case 'component':
          newWorkguide = update(workguide, {
            "components": {
              [id]: {
                "component": { $set: value.value }
              }
            }
          });
          //set defaultValue on DatePicker
          if (value.value === 'DateField') {
            newWorkguide = update(newWorkguide, {
              components: {
                [id]: {
                  props: {
                    type: { $set: "picker" }
                  }
                }
              }
            });
          }
          break;
        case 'componentType':
          newWorkguide = update(workguide, {
            components: {
              [id]: {
                props: {
                  type: {
                    $set: value,
                  },

                  ...value === 'input' || value === 'textarea'
                    ? {}
                    : { lookup: { $set: false } },
                },
              },
            },
          });
          break;
        case 'componentOption':
          const options = value.map((o) => {
            return ({ value: o.label, label: { de: o.label, en: o.label, fr: o.label } });
          });

          newWorkguide = update(workguide, {
            components: {
              [id]: {
                props: {
                  options: {
                    $set: options
                  }
                }
              }
            }
          });
          break
        case 'componentOptions':
          newWorkguide = update(newWorkguide, {
            components: {
              [id]: {
                props: {
                  options: {
                    $set: value
                  }
                }
              }
            }
          });
          break;
        case 'componentCodeGroup':
          newWorkguide = update(workguide, {
            components: {
              [id]: {
                props: {
                  group: { $set: value.value }
                }
              }
            }
          })
          break;
        case 'multi':
          newWorkguide = update(workguide, {
            components: {
              [id]: {
                props: {
                  multi: { $set: _.get(workguide, `components.${id}.props.multi`) === undefined ? false : !workguide.components[id].props.multi }
                }
              }
            }
          })
          break;
        case 'emailAttachments':
          newWorkguide = update(workguide, {
            components: {
              [id]: {
                props: {
                  emailAttachments: { $set: _.get(workguide, `components.${id}.props.emailAttachments`) === undefined ? false : !workguide.components[id].props.emailAttachments }
                }
              }
            }
          })
          break;
        case 'disabled':
          newWorkguide = update(workguide, {
            components: {
              [id]: {
                props: {
                  disabled: { $set: _.get(workguide, `components.${id}.props.disabled`) === undefined ? true : !workguide.components[id].props.disabled }
                }
              }
            }
          });
          break;

        case 'required':
          newWorkguide = update(workguide, {
            components: {
              [id]: {
                props: {
                  required: { $set: _.get(workguide, `components.${id}.props.required`) === undefined ? true : !workguide.components[id].props.required }
                }
              }
            }
          })
          break;
        case 'hidden':
          newWorkguide = update(workguide, {
            components: {
              [id]: {
                props: {
                  hidden: { $set: _.get(workguide, `components.${id}.props.hidden`) === undefined ? true : !workguide.components[id].props.hidden }
                }
              }
            }
          })
          break;
        case 'lookup':
          newWorkguide = update(workguide, {
            components: {
              [id]: {
                props: {
                  lookup: {
                    $set: value,
                  },
                },
              },
            },
          });
          break;
        case 'useAsMailSubject':
          newWorkguide = update(workguide, {
            components: {
              [id]: {
                props: {
                  useAsMailSubject: {
                    $set: value,
                  },
                },
              },
            },
          });
          break;
        case 'controlValue':
          newWorkguide = update(workguide, {
            components: {
              [id]: {
                props: {
                  controlValue: { $set: _.get(workguide, `components.${id}.props.controlValue`) === undefined ? true : !workguide.components[id].props.controlValue }
                }
              }
            }
          })
          break;
        case 'hideInMail':
          newWorkguide = update(workguide, {
            components: {
              [id]: {
                props: {
                  hideInMail: { $set: _.get(workguide, `components.${id}.props.hideInMail`) === undefined ? true : !workguide.components[id].props.hideInMail }
                }
              }
            }
          })
          break;
        case 'defaultValue':
          // Remove defaults if null
          if (_.isNull(value) || _.isUndefined(value)) {
            newWorkguide = update(workguide, {
              components: {
                [id]: { props: { $unset: ['defaults'] } }
              }
            });
          } else {
            newWorkguide = update(workguide, {
              components: {
                [id]: { props: { defaults: { $set: value } } }
              }
            })
          }

          break;
        case 'dependencies':
          if (_.isNull(value) || _.isUndefined(value)) {
            newWorkguide = update(workguide, {
              components: {
                [id]: { $unset: ['dependencies'] }
              }
            });
          } else {
            newWorkguide = update(workguide, {
              components: {
                [id]: { dependencies: { $set: value } }
              }
            })
          }
          break;
        case 'validations':
          newWorkguide = update(newWorkguide, {
            components: {
              [id]: { props: { validations: { $set: value } } }
            }
          });
          break;
        case 'hideValidations':
          newWorkguide = update(workguide, {
            components: {
              [id]: { props: { hideValidations: { $set: value } } }
            }
          });
          break;
        case 'prop':
          const operation = isUndefined(get(value, 'value'))
            ? { $unset: [value.key] }
            : { [value.key]: { $set: value.value } };

          newWorkguide = update(workguide, {
            components: {
              [id]: {
                props: {
                  ...operation
                }
              }
            }
          });

          break;
        case 'props':
          newWorkguide = update(workguide, {
            components: {
              [id]: {
                props: { $set: value }
              }
            }
          });
          break;
        case 'id':
          const component = _.get(workguide, `components.${id}`);
          if (!component) break;

          newWorkguide = update(workguide, {
            components: {
              [id]: {
                props: {
                  id: { $set: value }, name: { $set: value }
                }
              }
            }
          });

          // If the given component is a FieldGroup, we have to update
          // the fieldGroup of child components as well
          if (get(component, 'component') === 'FieldGroup') {
            get(workguide, 'components', [])
              .forEach((c, index) => {
                if (get(c, 'fieldGroup') === get(component, 'props.id')) {
                  newWorkguide = update(newWorkguide, {
                    components: {
                      [index]: { fieldGroup: { $set: value } }
                    }
                  })
                }
              });
          }

          break;
        case 'defaultStatus':
          if (_.isUndefined(value) || _.isNull(value)) {
            newWorkguide = update(workguide, {
              $unset: ['defaultStatus']
            });
          } else {
            newWorkguide = update(workguide, {
              defaultStatus: { $set: value }
            });
          }

          break;

        case 'category':
          if (_.isUndefined(value) || _.isNull(value)) {
            newWorkguide = update(workguide, {
              $unset: ['category']
            });
          } else {
            newWorkguide = update(workguide, {
              category: { $set: value }
            });
          }
          break;
        case 'archiveDocumentType':
          if (_.isUndefined(value) || _.isNull(value)) {
            newWorkguide = update(workguide, {
              $unset: ['archiveDocumentType']
            });
          } else {
            newWorkguide = update(workguide, {
              archiveDocumentType: { $set: value }
            });
          }
          break;
        case 'documentFindCode':
          if (_.isUndefined(value) || _.isNull(value)) {
            newWorkguide = update(workguide, {
              $unset: ['documentFindCode']
            });
          } else {
            newWorkguide = update(workguide, {
              documentFindCode: { $set: value }
            });
          }
          break;
        case 'findCode':
          if (_.isUndefined(value) || _.isNull(value)) {
            newWorkguide = update(workguide, {
              components: {
                [id]: { props: { $unset: ['findCode'] } }
              }
            });
          } else {
            newWorkguide = update(workguide, {
              components: {
                [id]: { props: { findCode: { $set: value } } }
              }
            });
          }
          break;
        case 'documentType':
          // Remove defaults if null
          if (_.isNull(value) || _.isUndefined(value)) {
            newWorkguide = update(workguide, {
              components: {
                [id]: { props: { $unset: ['documentType'] } }
              }
            });
          } else {
            newWorkguide = update(workguide, {
              components: {
                [id]: { props: { documentType: { $set: value } } }
              }
            });
          }
          break;
        case 'orderOrigin':
          if (_.isUndefined(value) || _.isNull(value)) {
            newWorkguide = update(workguide, {
              $unset: ['orderOrigin']
            });
          } else {
            newWorkguide = update(workguide, {
              orderOrigin: { $set: value }
            });
          }
          break;
        case 'possibleRejectReasons':
          if (_.isNull(value) || _.isUndefined(value) || get(value, 'length', 0) === 0) {
            newWorkguide = update(workguide, {
              $unset: ['possibleRejectReasons']
            });
          } else {
            newWorkguide = update(workguide, {
              possibleRejectReasons: { $set: value }
            });
          }
          break;
        case 'infoboxTitle':
          newWorkguide = update(workguide, {
            components: {
              [id]: { props: { infoboxTitle: { $set: value } } }
            }
          });
          break;

        case 'infoboxContent':
          newWorkguide = update(workguide, {
            components: {
              [id]: { props: { infoboxContent: { $set: value } } }
            }
          });
          break;
        case 'documentType':
          // Remove defaults if null
          if (_.isNull(value) || _.isUndefined(value)) {
            newWorkguide = update(workguide, {
              components: {
                [id]: { props: { $unset: ['documentType'] } }
              }
            });
          } else {
            newWorkguide = update(workguide, {
              components: {
                [id]: { props: { documentType: { $set: value } } }
              }
            });
          }
          break;
        case 'filename':
          // Remove defaults if null
          if (_.isNull(value) || _.isUndefined(value)) {
            newWorkguide = update(workguide, {
              components: {
                [id]: { props: { $unset: ['filename'] } }
              }
            });
          } else {
            newWorkguide = update(workguide, {
              components: {
                [id]: { props: { filename: { $set: value } } }
              }
            });
          }
          break;
        case 'allowedMimeTypes':
          // Remove defaults if null
          if (_.isNull(value) || _.isUndefined(value)) {
            newWorkguide = update(workguide, {
              components: {
                [id]: { props: { $unset: ['allowedMimeTypes'] } }
              }
            });
          } else {
            newWorkguide = update(workguide, {
              components: {
                [id]: { props: { allowedMimeTypes: { $set: value } } }
              }
            });
          }
          break;
        case 'specialComponentConfig':
          if (_.isArray(value)) {
            newWorkguide = update(workguide, {
              components: {
                [id]: { props: { specialComponentConfig: { $set: value } } }
              }
            });
          }
          break;
        case 'hiddenCols':
          let hiddenCols = [..._.get(workguide, `components.${id}.props.hiddenCols`, [])];
          const index = hiddenCols.findIndex(c => c === value);
          (index > -1)
            ? hiddenCols.splice(index, 1)
            : hiddenCols.push(value)

          newWorkguide = update(workguide, {
            components: {
              [id]: { props: { hiddenCols: { $set: hiddenCols } } }
            }
          });

          break;
        case 'defaultFilter':
          const filter = value || [];

          newWorkguide = update(workguide, {
            components: {
              [id]: { props: { defaultFilter: { $set: filter } } }
            }
          });
          break;
        case 'colTitles':
          const colTitles = value || [];

          newWorkguide = update(workguide, {
            components: {
              [id]: { props: { colTitles: { $set: colTitles } } }
            }
          });
          break;
        case 'documentSpecificAttribute':
          const documentSpecificAttribute = value || [];

          newWorkguide = update(workguide, {
            components: {
              [id]: { props: { documentSpecificAttribute: { $set: documentSpecificAttribute } } }
            }
          });
          break;
        case 'approval':
          newWorkguide = update(workguide, {
            approval: { $set: value }
          });

          break;
        case 'userRoles':
          newWorkguide = update(workguide, {
            userRoles: { $set: value.map(role => role.value) }
          });
          break;
        case 'camunda':
          newWorkguide = update(workguide, { camunda: { $set: value } });

          break;
        case 'workguidePropery':
          const key = get(value, 'key');
          const v = get(value, 'value');

          newWorkguide = isUndefined(v) || isNull(v)
            ? update(workguide, { $unset: [key] })
            : update(workguide, { [key]: { $set: v } });
          break;
        default:
          console.warn(`type -- ${type} -- not available`);
          newWorkguide = workguide;
      }
    }
    this.props.actions.setData({ key: "workguide", value: newWorkguide });
    this.requestUpdateService(newWorkguide);
  }

  /**
   * Handle onChange event of workguide actions
   *
   * @param  {String} which   Which actions (pre/post)
   * @param  {Array}  actions Array of actions
   *
   * @return void
   */
  onWorkguideActionsChange(which, actions) {
    const { workguide } = this.props;
    let updated = workguide;
    // Make sure we have an actions attribute on current workguide
    if (!_.has(updated, 'actions')) {
      updated = update(updated, {
        actions: { $set: { post: [] } }
      });
    }

    updated = update(updated, {
      actions: { [which]: { $set: actions } }
    });

    this.props.actions.setData({
      key: 'workguide',
      value: updated
    });

    this.requestUpdateService(updated)
  }

  getNavigation() {
    const { selectedTab, tabs } = this.state;
    const tabNav = tabs.map((tab) => {
      return (
        <NavItem key={tab} eventKey={tab}>
          {tab}
        </NavItem>)
    }
    );

    return (
      <Nav bsStyle="tabs" activeKey={selectedTab} onSelect={k => this.handleSelect(k)}>
        {tabNav}
      </Nav>
    )
  }

  getWorkguideEdit() {
    const { selectedTab } = this.state;
    const {
      language,
      workguide,
      workguides,
      consultants,
      codeGroups,
      codes,
      actions,
      productGroups
    } = this.props;

    switch (selectedTab) {
      case "Allgemein":
        return (
          <General
            consultants={consultants}
            workguide={workguide}
            language={language}
            codes={codes}
            editValueField={this.editValueField}
          />
        );
      case "Prozessdetails":
        return (
          <DetailInfos
            editValueField={this.editValueField}
            content={workguide.processDescription[language]}
            targetField="processDescription"
            title="Prozessdetails erfassen"
          />
        );
      case "Formularfelder":
        return (
          <Components
            addComponent={() => this.addComponent()}
            addFieldGroupComponent={this.addFieldGroupComponent}
            codeGroups={codeGroups}
            codes={codes}
            consultants={consultants}
            delComponent={this.delComponent}
            duplicateComponent={this.duplicateComponent}
            editValueField={this.editValueField}
            fetchCodes={actions.workguideCodesRequest}
            helperClass="SortableHOCHelper"
            items={workguide.components}
            language={language}
            onSortEnd={this.onSortEnd}
            // Pass onSortEnd as dropHandler as onSortEnd gets removed by the sortable hoc
            dropHandler={this.onSortEnd}
            productGroups={productGroups}
            useDragHandle
            useWindowAsScrollContainer
            workguide={workguide}
            workguides={workguides}
          />
        );
      case 'Freigabe':
        return (
          <Approvals
            workguide={workguide}
            codes={codes}
            fetchCodes={actions.workguideCodesRequest}
            language={language}
            editValueField={this.editValueField}
            consultants={consultants}
          />
        );
      case "Info":
        return (
          <DetailInfos
            editValueField={this.editValueField}
            content={workguide.info[language]}
            targetField="info"
            title="Weitere Infos erfassen"
          />
        );
      case "Hilfe":
        return (
          <DetailInfos
            editValueField={this.editValueField}
            language={language}
            content={workguide.help[language]}
            targetField="help"
            title="Hilfe erfassen"
          />
        );
      case "FAQ":
        return (
          <DetailInfos
            editValueField={this.editValueField}
            language={language}
            content={workguide.faq[language]}
            targetField="faq"
            title="FAQ erfassen"
          />
        );
      case "Aktionen":
        return (
          <React.Fragment>
            <h3>Aktionen</h3>
            <Actions
              title="Post"
              actions={_.get(workguide, 'actions.post', [])}
              components={_.get(workguide, 'components', [])}
              workguides={workguides}
              onChange={(actions) => this.onWorkguideActionsChange('post', actions)}
              codes={codes}
              fetchCodes={actions.workguideCodesRequest}
            />
          </React.Fragment>
        );
      default:
        return (<h1>Allgemein</h1>);
    }
  }

  getWorkguideDetails() {
    return (
      <div>
        {this.getNavigation()}
        {this.getWorkguideEdit()}
      </div>
    )
  }

  footer() {
    const { requesting } = this.props;
    return (
      <span className="pull-left">
        <Button bsStyle="danger" onClick={this.deleteWorkguide} disabled={requesting}>
          Diesen Workguide löschen
        </Button>
        <Button style={{ marginLeft: 20 }} bsStyle="primary" onClick={this.cloneWorkguide} disabled={requesting}>
          Diesen Workguide clonen
        </Button>
      </span>
    )
  }

  cloneWorkguide() {
    const { actions, workguide } = this.props;
    const newWorkguide = update(workguide, {
      id: { $set: uuid.v4() },
      title: {
        de: { $set: `${workguide.title.de}_CLONE` },
        fr: { $set: `${workguide.title.fr}_CLONE` },
        en: { $set: `${workguide.title.en}_CLONE` }
      }
    })
    actions.setData({ key: "workguide", value: newWorkguide });
    this.requestUpdateService(newWorkguide);
  }

  deleteWorkguide() {
    const {
      actions,
      history,
      match
    } = this.props;
    const workguideId = get(match, 'params.workguideId');

    actions.setData({ key: "workguide", value: {} });
    actions.deleteWorkguideRequest(workguideId);
    history.push('/workguide');
  }

  render() {
    const { requesting, workguide, language } = this.props;
    const header = (
      <div>
        <Toggle
          checked={workguide.active === undefined ? false : workguide.active}
          onChange={this.editValueField.bind(this, 'active', 'toggle')}
        />
        <span style={{ marginLeft: 20 }} >
          {`Workguide ${_.get(workguide, `title.${language}`)} (${language})`}
        </span>
      </div>
    );
    return (
      <EditContainer
        requesting={requesting}
        title={header}
        body={this.getWorkguideDetails()}
        footer={this.footer()}
        closeLink="/workguide"
      />
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    workguide: state.workguide.workguide,
    workguides: state.workguide.workguides,
    consultants: state.workguide.consultants,
    requesting: state.workguide.requesting,
    language: state.login.language,
    codeGroups: state.workguide.codeGroups,
    codes: state.workguide.codes,
    productGroups: state.workguide.productGroups
  }
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(workguideActions, dispatch) }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(WorkguideDetail)
);
