import { call, put, delay } from 'redux-saga/effects';
import { get } from 'lodash';

import actions from '../actions/Actions';
import updateLead from './updateLead';
import saveLeadHistory from '../../../actions/LeadHistory/SaveLeadHistory';

const {
  EDIT_FORM_SAVE_PENDING,
  EDIT_FORM_SAVE_FULFILLED,
  EDIT_FORM_SAVE_REJECTED
} = actions;

/**
 * Update a single lead.
 * Add a small delay as the backend may have problems with to many requests at a time
 *
 * @param   {Object}  formData  Form data with changes
 * @param   {Obejct}  lead      Current lead
 *
 * @return  {Object} result Result from updateLead call
 */
function* update({ formData, lead }) {
  const result = yield call(updateLead, { dataKey: lead.id, lead, changes: formData });

  yield delay(200);

  return result;
}

export default function* saveEditForm({ formData, leads, consultantId }) {
  yield put({ type: EDIT_FORM_SAVE_PENDING });
  try {
    const paylaod = [];
    // eslint-disable-next-line
    for (const lead of leads) {
      const result = yield call(update, { lead, formData });
      paylaod.push(result);
    }

    const leadIds = leads.map((lead) => get(lead, 'id'));
    yield call(saveLeadHistory, { consultantId, leadIds, type: 'Massmutation' });

    yield put({ type: EDIT_FORM_SAVE_FULFILLED, paylaod });
  } catch (error) {
    yield put({ type: EDIT_FORM_SAVE_REJECTED, error });
  }
}
