import { call, put, select } from 'redux-saga/effects';
import axios from 'axios';
import update from 'immutability-helper';
import { chain, get, has, isUndefined } from 'lodash';
import { removeUnwantedValues } from '@evoja-web/redux-saga-utils';
import moment from 'moment';

import updateLeadAction from '../actions/UpdateLead';

const {
  UPDATE_LEAD_PENDING,
  UPDATE_LEAD_FULFILLED,
  UPDATE_LEAD_REJECTED
} = updateLeadAction;

export default function* updateLead(request) {
  const {
    dataKey,
    changes,
    lead
  } = request;

  yield put({ type: UPDATE_LEAD_PENDING, dataKey });

  try {
    const session = yield select((state) => get(state, 'login.session'));

    // Checking if the lead is closed
    const isLeadClosed = isUndefined(get(changes, 'customerTaskStatus.id'))
      ? false
      : [
        'customerTaskStatus-55',
        'customerTaskStatus-54',
        'customerTaskStatus-53',
        'customerTaskStatus-52',
        'customerTaskStatus-51',
        'customerTaskStatus-50',
        'customerTaskStatus-2'
      ].includes(get(changes, 'customerTaskStatus.id'));

    // Prepare the new changes object
    const newLeadObject = { ...changes };

    if (isLeadClosed) {
      newLeadObject.resolvedDate = new Date();
      newLeadObject.isClosed = true;
    } else {
      // Make sure resolvedDate is removed if not in closed status
      if (has(lead, 'resolvedDate')) newLeadObject.resolvedDate = null;
      newLeadObject.isClosed = false;
    }

    // Preparing the updated lead data
    const updated = chain(newLeadObject)
      .thru((newLeadObject) => removeUnwantedValues(newLeadObject, [undefined, null]))
      .reduce((result, value, key) => update(result, { [key]: { $set: value } }), lead)
      .value();

    const modification = [
      ...get(lead, 'modification', []),
      removeUnwantedValues({
        date: moment().format(),
        oldCustomerTaskStatus: get(lead, 'customerTaskStatus.id'),
        consultantId: get(session, 'id'),
        oldDueDate: get(lead, 'dueDate'),
        oldExpireDate: get(lead, 'expireDate'),
        oldCustomerResponse: get(lead, 'customerResponse')
      }, [undefined, null])
    ];

    const payload = {
      ...updated,
      modification
    };

    const url = `/work/leadmanagement/${lead.id}`;
    const result = yield call(axios, {
      url,
      method: 'put',
      data: payload
    });

    yield put({ type: UPDATE_LEAD_FULFILLED, dataKey, payload, result });

    return payload;
  } catch (error) {
    yield put({ type: UPDATE_LEAD_REJECTED, dataKey, error });

    return error;
  }
}
