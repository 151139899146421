import axios from 'axios';
const parseLinkHeader = require('parse-link-header');
const parseUrl = require('url-parse');

/**
 * Follow the next headers returned from backend
 *
 * @param  {[type]} params    [description]
 * @param  {Array}  [data=[]] [description]
 *
 * @return {Array} data Array of data
 */
export default async function followNextHeaders(params, data = []) {
  const result = await axios(params);
  const linkHeaders = parseLinkHeader(result.headers.link);

  data = [ ...data, ...result.data ];
  if (linkHeaders.hasOwnProperty('next')) {
    const parsed = parseUrl(linkHeaders.next.url);
    const url = `${parsed.pathname}${parsed.query.replace(/(%2C)/ig, ",")}`;
    return followNextHeaders({ ...params, url }, data);
  }

  return data;
}
