import axios from 'axios';
import { take, call, put } from 'redux-saga/effects';
import _ from 'lodash';
import parseLinkHeader from 'parse-link-header';

import { removeHost } from '../utils'

import {
  REQUEST,
  GET,
  PUT
} from '../actions/Leadqualification';
import { EndpointName } from '../constants/EndpointName';

export function* getLeadqualification() {
  try {
    yield put({ type: `${GET}_PENDING`})

    //get all customerTaskTypes to populate page
    let leadtypes = []
    let nextLeadtypes = [];
    let linkHeaders = { next: { url: 'http://localhost/entity/code/?eq(group,customerTaskType)&sort(+text.de)&limit(100)' } };
    while (linkHeaders.hasOwnProperty('next')) {
      nextLeadtypes = yield call(axios.get, removeHost(linkHeaders.next.url));
      leadtypes = _.concat(leadtypes, nextLeadtypes.data);
      linkHeaders = parseLinkHeader(nextLeadtypes.headers.link)
    }


    //get all leadqualifications to populate page
    let leadqualification = []
    let nextLeadqualification = [];
    linkHeaders = { next: { url: '/work/leadqualification/?limit(100)' } };
    while (linkHeaders.hasOwnProperty('next')) {
      nextLeadqualification = yield call(axios.get, removeHost(linkHeaders.next.url));
      leadqualification = _.concat(leadqualification, nextLeadqualification.data);
      linkHeaders = parseLinkHeader(nextLeadqualification.headers.link)
    }

    const emptyQualification = {
      customerTaskTypeId: 0,
      importance: 1,
      forNewLeadAvailable: false,
      workguideId: "",
      leadinstruction: {
        de: "",
        fr: "",
        en: ""
      },
      notificationOptions: [],
    };

    const qualifiedLeads = leadtypes.map((o) => {
      const matchedTaskType = _.find(leadqualification, { customerTaskTypeId: o.id });
      if (!matchedTaskType) {
        return Object.assign(
          {},
          emptyQualification,
          {
            customerTaskTypeId: o.id,
            id: o.id,
            leadTypeText: o.text
          }
        )
      } else {
        return Object.assign({}, emptyQualification, matchedTaskType, { leadTypeText: o.text });
      }

    });

    //get all workguide titles and IDs for dropdown menu
    let workguides = []
    let nextworkguides = [];
    linkHeaders = { next: { url: '/work/workguide/?select(title)&limit(100)' } };
    while (linkHeaders.hasOwnProperty('next')) {
      nextworkguides = yield call(axios.get, removeHost(linkHeaders.next.url));
      workguides = _.concat(workguides, nextworkguides.data);
      linkHeaders = parseLinkHeader(nextworkguides.headers.link)
    }

    yield put({ type: `${GET}_WORKGUIDES_FULFILLED`, data: workguides });

    //get all leadGroups to populate dropdown
    let LeadGroup = []
    let nextLeadGroup = [];
    linkHeaders = { next: { url: 'http://localhost/entity/code/?eq(group,leadGroup)&sort(+number)&limit(100)' } };
    while (linkHeaders.hasOwnProperty('next')) {
      nextLeadGroup = yield call(axios.get, removeHost(linkHeaders.next.url));
      LeadGroup = _.concat(LeadGroup, nextLeadGroup.data);
      linkHeaders = parseLinkHeader(nextLeadGroup.headers.link)
    }

    const customerTaskIds = ['customerTaskStatus-52', 'customerTaskStatus-53'];
    const { data: customerTaskStatuses } = yield call(axios, {
      url: `${EndpointName.ENTITY_CODE}/?in(id,(${customerTaskIds.join(',')}))`
    });

    yield put({ type: `${GET}_LEADGROUPS_FULFILLED`, data: LeadGroup });
    yield put({ type: `${GET}_CUSTOMER_TASK_STATUSES_FULFILLED`, data: customerTaskStatuses });
    yield put({ type: `${GET}_FULFILLED`, data: qualifiedLeads });

  } catch(e) {
    yield put({ type: `${GET}_REJECTED` });
  }
}


export function* updateLeadqualification(leadQualification) {
  try {
    yield put({ type: `${PUT}_PENDING` });
    yield call(axios.put, `/work/leadqualification/${leadQualification.id}`, leadQualification)
    yield put({ type: `${PUT}_FULFILLED` });
  } catch(e) {
    yield put({ type: `${PUT}_REJECTED` });
  }
}


export default function* leadqualificationFlow() {
  while (true) {
    const request = yield take(REQUEST);
    const { leadqualification } = request;
    if (leadqualification) {
      yield call(updateLeadqualification, leadqualification);
    } else {
      yield call(getLeadqualification)

    }
  }
}
